<template>
  <div>
    <div class="flexable between navigation" v-show="navShow">
      <div class="flexable">
        <img :src="logo" alt="" style="object-fit: contain;" class="logo">
        <!-- <div style="margin-left: 0.2rem;">
          <van-dropdown-menu >
            <van-dropdown-item v-model="valueLang" :options="optionLang" @change="onChange" />
          </van-dropdown-menu>
        </div> -->
      </div>
      <div class="flexable">
        <div class="color_w flexable jusCenter btn_download" @click="download('android')">{{$t('topBar.downloadApp')}}</div>
        <img src="@/common/img/DeTok/more.png" alt="" class="navMore" @click="controlToast('show')">
      </div>
    </div>

    <!-- 首页 -->
    <div id="homePage">
      <img src="@/common/img/DeTok/bg_homepage.png" alt="" class="homePage_bg">
      <img src="@/common/img/DeTok/girl.png" alt="" class="homePage_girl">
      <ul class="homePage_box">
        <img src="@/common/img/DeTok/hp_font.png" alt="" srcset="" class="homePage_imgFont">
        <img src="@/common/img/DeTok/hp_font1.png" alt="" srcset="" class="homePage_imgFont1">
        <p class="color_w homePage_des font_28">
          {{$t('home.des').replace('XXXXX', userNums)}}<br>
          {{$t('home.des1')}}<br>
          {{$t('home.des2').replace('XXXXX', userNums)}}
        </p>
        <div class="homePage_btnBox">
          <li class="homePage_btn flexable jusCenter color_w" @click="download('android')">
            <img src="@/common/img/DeTok/icon_android.png" alt="" class="homePage_btnImg">
            {{$t('home.btn1')}}
          </li>
        </div>
      </ul>
      <!-- <van-swipe style="height: 100%;" :autoplay="300000" indicator-color="white">
        <van-swipe-item style="width: 100vw;height: 100%;position: relative;">
          
        </van-swipe-item>
        <van-swipe-item style="width: 100vw;height: 100%;">
          <img src="https://image2.juhaowu.cn/cn_image/dbc10dce-82a9-4edc-889b-540c0d1f69fd.jpg" alt="" style="width: 100vw;height: 100%;object-fit: cover;" />
        </van-swipe-item>
      </van-swipe> -->
    </div>

    <!-- 商业模式 -->
    <div id="businessModel">
      <div class="title_public">{{$t('businessModel.title')}}</div>
      <ul>
        <li class="businessModelItem flexable column jusCenter text_center marTop20" v-for="item in businessModelList" :key="item.title">
          <img :src="item.icon" alt="" style="width: 42.6vw;">
          <p class="font_bold color_fontTitle font_32 marTop40">{{$t(item.title)}}</p>
          <p class="color_fontDes font_26 marTop8 font_bold lh48">{{$t(item.des)}}</p>
          <ul class="color_fontDes font_24 lh48 marTop20 text_left" v-if="item.details">
            <li v-for="detail in $t(item.details)" :key="detail">{{$t(detail)}}</li>
          </ul>
        </li>
      </ul>
      <div class="swiper-container" style="margin-top: 15vw;">
				<div class="swiper-wrapper">
					<div class="swiper-slide slideSwiper" v-for="(item, index) in swiprtList" :key="item + index">
						<img :src="item" alt="" style="width: 100%;height: 100%;" @click="controlView(item)">
					</div>
				</div>
			</div>
    </div>

    <!-- 规划特点 -->
    <div id="plan">
      <div class="title_public">{{$t('plan.title')}}</div>
      <ul class="flexable wrap">
        <li :class="item.class" v-for="item in planList" :key="item.title">
          <p :class="item.classTitle">{{ $t(item.title) }}</p>
          <p :class="item.classDes">{{ $t(item.des) }}</p>
        </li>
      </ul>
      <img src="@/common/img/DeTok/plan.png" alt="" class="planImg">
      <!-- <img src="@/common/img/DeTok/shape_r.png" alt="" class="planImg_shape"> -->
      <img src="@/common/img/DeTok/shape_l.png" alt="" class="planImg_shape1">
    </div>

    <!-- 将重塑场景 -->
    <div id="scene">
      <div class="title_public">{{$t('scene.title')}}</div>
      <ul class="flexable between wrap">
        <li :class="{'flexable': true, 'column': true, 'scene_item': index != 0,'scene_itemOne': index == 0}" v-for="(item, index) in sceneList" :key="item.title">
          <img :src="item.url" alt="scene_itemImg" class="scene_itemImg">
          <p class="font_32 color_fontTitle font_bold marTop30">{{$t(item.title)}}</p>
          <p class="font_24 color_fontDes marTop8">{{$t(item.des)}}</p>
        </li>
      </ul>
    </div>

    <!-- 应用场景 -->
    <div id="repurchase">
      <div class="title_public">{{$t('repurchase.title')}}</div>
      <img src="@/common/img/DeTok/buy_back.png" alt="" style="width: 87.2vw;">
      <ul class="font_28 color_fontDes font_bold">
        <li class="flexable marTop40" v-for="item in repurchaseList" :key="item.icon" style="margin-top: 0.5rem;">
          <img :src="item.icon" alt="" style="width: 9.6vw;">
          <div class="marLeft20 lh40">{{$t(item.des)}}</div>
        </li>
      </ul>
    </div>

    <!-- 官方&&CEO 信息 -->
    <div id="footer">
      <div class="footer_icon font_32 color_w font_bold">
        <div>
          <p>{{$t('contact.official')}}</p>
          <ul class="flexable wrap">
            <li v-for="item in linkOfficial" :key="item.id" :style="{'marginLeft': item.ml || '5.3vw'}" @click="openLink(item.link)">
              <img :src="item.icon" alt="" srcset="" style="width: 9vw;">
            </li>
          </ul>
        </div>
        <div style="margin-top: 10.6vw;">
          <p>{{$t('contact.ceo')}}</p>
          <ul class="flexable wrap">
            <li v-for="item in linkCeo" :key="item.id" :style="{'marginLeft': item.ml || '5.3vw'}" @click="item.code ? controlView(item.link) : openLink(item.link)">
              <img :src="item.icon" alt="" srcset="" style="width: 9vw;">
            </li>
          </ul>
        </div>
      </div>
      <div class="footer_else text_center color_w">
        <!-- <p class="bgAtoshi font_24">{{$t('contact.by')}}</p> -->
        <a href="http://beian.miit.gov.cn" target="_blank" class="icp color_w font_20 marTop30" >{{icp}}</a>
      </div>
    </div>
    
    <van-popup v-model="show" :closeable="true" position="left" class="popupShadaw">
      <div class="flex" style="flex-direction: column;">
        <ul class="toastBox">
          <li :class="{'active': active == '#homePage'}" @click="toastNav('#homePage')">
            <img src="@/common/img/nav/icon_1.png">
            <span>{{$t('topBar.nav1')}}</span>
          </li>
          <li :class="{'active': active == '#businessModel'}" @click="toastNav('#businessModel')">
            <img src="@/common/img/nav/icon_2.png">
            <span>{{$t('topBar.nav2')}}</span>
          </li>
          <li :class="{'active': active == '#plan'}" @click="toastNav('#plan')">
            <img src="@/common/img/nav/icon_3.png">
            <span>{{$t('topBar.nav3')}}</span>
          </li>
          <li :class="{'active': active == '#scene'}" @click="toastNav('#scene')">
            <img src="@/common/img/nav/icon_4.png">
            <span>{{$t('topBar.nav4')}}</span>
          </li>
          <li :class="{'active': active == '#repurchase'}" @click="toastNav('#repurchase')">
            <img src="@/common/img/nav/icon_5.png">
            <span>{{$t('topBar.nav5')}}</span>
          </li>
          <li :class="{'active': active == '#footer'}" @click="toastNav('#footer')">
            <img src="@/common/img/nav/icon_6.png">
            <span>{{$t('topBar.nav6')}}</span>
          </li>
        </ul>
      </div>
    </van-popup>

    <van-popup v-model="videoShow" closeable style="width:100%" @close="onClose">
      <video :src="videoSrc" controls autoplay style="width: 100%;" class="videoToast"></video>
    </van-popup>

    <van-popup v-model="picShow" closeable style="width: 100%;text-align: center;background: rgba(0,0,0,0);" :close-on-click-overlay="false">
      <img :src="picSrc" controls autoplay style="width: 60vw;" />
    </van-popup>

    <van-overlay :show="browerFlag" z-index="999" @click="controlBrower">
      <img src="@/common/img/DeTok/webdown.png" class="img_brower" />
    </van-overlay>
  </div>
</template>

<script>
import $ from 'jquery';
import { Toast } from 'vant';
import { langSet } from '@/utils';
// import axios from 'axios';
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import BigNumber from 'bignumber.js';
const APK_URL = "https://image.leshua.pro/apk/DeTok.apk";
const APK_URL_BETA = "https://image.leshua.pro/flutter/apk/DeTok.apk";
export default {
  data() {
    return {
      browerFlag: false,
      isWeixin: false,
      isAndroid: false,
      isiOS: false,
      logo: require('@/common/img/DeTok/logo.png'),
      picShow: false,
      picSrc: "",
      swiprtList: [
				'https://img.leshuapro.com/wangzhuang_images/3b3af886-fdb4-47d2-b68d-cba248e25097.jpg',
				'https://img.leshuapro.com/wangzhuang_images/177a3fe6-e1cc-4b4a-a87c-640f1a32a28b.jpg',
				'https://img.leshuapro.com/wangzhuang_images/7541059c-f6e7-403d-a503-3b942057bfc5.jpg',
				'https://img.leshuapro.com/wangzhuang_images/93edfb46-a719-4a34-a6e2-0fc330a73cc7.jpg',
				'https://img.leshuapro.com/wangzhuang_images/051ed5e9-16d7-4423-9e00-599032b908af.jpg',
				'https://img.leshuapro.com/wangzhuang_images/57aee3e0-a894-4752-be10-950c3ac86b8d.jpg',
				'https://img.leshuapro.com/wangzhuang_images/f610b38d-7e4e-4bd1-8259-476a19d8d13a.jpg'
			],
      optionLang: [
        { text: "CH", value: 'ch' },
        { text: "EN", value: 'en' }
      ],
      valueLang: 'ch',
      show: false,
      videoShow: false,
      showWechat: false,
      videoSrc: "",
      active: '',
      sceneList: [
        {
          url: require("@/common/img/DeTok/scene1.png"),
          title: 'scene.modal1.title',
          des: 'scene.modal1.des'
        },
        {
          url: require("@/common/img/DeTok/scene2.png"),
          title: "scene.modal2.title",
          des: "scene.modal2.des"
        },
        {
          url: require("@/common/img/DeTok/scene3.png"),
          title: "scene.modal3.title",
          des: "scene.modal3.des"
        },
        {
          url: require("@/common/img/DeTok/scene4.png"),
          title: "scene.modal4.title",
          des: "scene.modal4.des"
        },
        {
          url: require("@/common/img/DeTok/scene5.png"),
          title: "scene.modal5.title",
          des: "scene.modal5.des"
        }
      ],
      isEn: false,
      icp: "琼ICP备2020004909号-6",
      icpShow: true,
      linkOfficial: [
        {
          id: 1,
          icon: require('@/common/img/bottom/icon_atoshi.png'),
          link: "https://atoshi.org",
          ml: '0'
        },
        {
          id: 2,
          icon: require('@/common/img/bottom/icon_vt.png'),
          link: "https://atoshi.org/pch/info.html",
        },
        {
          id: 3,
          icon: require('@/common/img/bottom/icon_wb.png'),
          link: "https://weibo.com/7404867544/profile?rightmod=1&wvr=6&mod=personnumber",
        },
        {
          id: 4,
          icon: require('@/common/img/bottom/icon_imfo.png'),
          link: "https://imfo.world",
        },
        {
          id: 5,
          icon: require('@/common/img/bottom/icon_atos.png'),
          link: "https://scan.atoverse.info",
        },
        {
          id: 6,
          icon: require('@/common/img/bottom/icon_hwj.png'),
          link: "https://www.haowuju.net/#/index",
        },
        {
          id: 7,
          icon: require('@/common/img/bottom/x.png'),
          link: "https://twitter.com/atoshiofficial",
          ml: '0'
        },
        {
          id: 8,
          icon: require('@/common/img/bottom/icon_fb.png'),
          link: "https://www.facebook.com/atoshifoundation/",
        },
        {
          id: 9,
          icon: require('@/common/img/bottom/icon_yt.png'),
          link: "https://www.youtube.com/channel/UCnerJ-uKVBfHR82kpYXJxMQ/videos",
        },
        {
          id: 10,
          icon: require('@/common/img/bottom/icon_in.png'),
          link: "https://www.linkedin.com/company/atoshiofficial",
        },
        {
          id: 11,
          icon: require('@/common/img/bottom/icon_tg.png'),
          link: "https://t.me/atoshiofficial",
        },
        {
          id: 12,
          icon: require('@/common/img/bottom/icon_tg1.png'),
          link: "https://t.me/atoshichannel",
        }
      ],
      linkCeo: [
        {
          id: 1,
          icon: require('@/common/img/bottom/icon_wb.png'),
          link: "https://weibo.com/liaodoer",
          ml: '0'
        },
        {
          id: 2,
          icon: require('@/common/img/bottom/icon_dt.png'),
          link: require('@/common/img/bottom/code_ls.png'),
          code: true
        },
        {
          id: 3,
          icon: require('@/common/img/bottom/icon_dy.png'),
          link: require('@/common/img/bottom/code_dy.png'),
          code: true
        },
        {
          id: 4,
          icon: require('@/common/img/bottom/icon_ks.png'),
          link: require('@/common/img/bottom/code_ks.png'),
          code: true
        },
        {
          id: 5,
          icon: require('@/common/img/bottom/icon_xl.png'),
          link: "http://www.ximalaya.com/zhubo/9845641?uid=9845641",
        },
        {
          id: 6,
          icon: require('@/common/img/bottom/icon_qq.png'),
          link: "https://user.qzone.qq.com/75510696/infocenter",
        },
        {
          id: 7,
          icon: require('@/common/img/bottom/icon_fb.png'),
          link: "https://www.facebook.com/leomarsliao/",
          ml: '0'
        },
        {
          id: 8,
          icon: require('@/common/img/bottom/x.png'),
          link: "https://x.com/leomarsliao?t=avcMfhLQsIAGj0GbVMYfPA&s=09",
        },
        {
          id: 9,
          icon: require('@/common/img/bottom/icon_in.png'),
          link: "https://www.linkedin.com/in/leomars/",
        },
        {
          id: 10,
          icon: require('@/common/img/bottom/icon_instagram.png'),
          link: "https://www.instagram.com/liaodoer/",
        },
      ],
      weChatList: [
        {
          id: 1,
          icon: require('../common/img/bottom/icon_wx.png'),
          link: require('../common/img/bottom/code_wx1.png'),
          text: "（微信客服1）",
          code: true,
        },
        // {
        //   id: 2,
        //   icon: require('../common/img/bottom/icon_wx.png'),
        //   link: require('../common/img/bottom/code_wx2.png'),
        //   text: "（微信客服2）",
        //   code: true,
        // }
      ],
      serviceList: [
        {
          id: 1,
          icon: require('../common/img/bottom/icon_wx.png'),
          link: require('../common/img/bottom/code_wx2.png'),
          code: true,
        },
        {
          id: 2,
          icon: require('../common/img/bottom/icon_tg.png'),
          link: require('../common/img/bottom/code_tg.png'),
          code: true,
        },
        {
          id: 3,
          icon: require('../common/img/bottom/icon_wu.png'),
          link: require('../common/img/bottom/code_wu.png'),
          code: true,
        },
      ],
      iosBetaUrl: "https://img.leshuapro.com/wangzhuang_images/7a65e6a4-6fe7-48f9-bef9-a501e7433071.png",
      betaFlag: false,
      repurchaseList: [
        {
          icon: require("@/common/img/DeTok/1.png"),
          des: 'repurchase.modal1.des'
        },
        {
          icon: require("@/common/img/DeTok/2.png"),
          des: "repurchase.modal2.des"
        },
        {
          icon: require("@/common/img/DeTok/3.png"),
          des: "repurchase.modal3.des"
        },
        {
          icon: require("@/common/img/DeTok/4.png"),
          des: "repurchase.modal4.des"
        },
        {
          icon: require("@/common/img/DeTok/5.png"),
          des: "repurchase.modal5.des"
        },
        {
          icon: require("@/common/img/DeTok/6.png"),
          des: "repurchase.modal6.des"
        }
      ],
      planList: [
        {
          title: "plan.modal1.title",
          des: 'plan.modal1.des',
          class: "planItem_top color_plan1",
          classTitle: "font_36 color_fontTitle1 font_bolder",
          classDes: "marTop20 font_24 color_fontDes lh40"
        },
        {
          title: "plan.modal2.title",
          des: 'plan.modal2.des',
          class: "planItem_top color_plan2",
          classTitle: "font_36 color_fontTitle1 font_bolder text_right",
          classDes: "marTop20 font_24 color_fontDes lh40 text_right"
        },
        {
          title: "plan.modal3.title",
          des: 'plan.modal3.des',
          class: "planItem_btm color_plan3",
          classTitle: "font_36 color_fontTitle1 font_bolder",
          classDes: "marTop20 font_24 color_fontDes lh40"
        },
        {
          title: "plan.modal4.title",
          des: 'plan.modal4.des',
          class: "planItem_btm color_plan4",
          classTitle: "font_36 color_fontTitle1 font_bolder text_right",
          classDes: "marTop20 font_24 color_fontDes lh40 text_right"
        }
      ],
      businessModelList: [
        {
          icon: require("@/common/img/DeTok/bm1.png"),
          title: "businessModel.modal1.title",
          des: 'businessModel.modal1.des',
          details: "businessModel.modal1.details"
        },
        {
          icon: require("@/common/img/DeTok/bm2.png"),
          title: "businessModel.modal2.title",
          des: 'businessModel.modal2.des'
        },
        {
          icon: require("@/common/img/DeTok/bm3.png"),
          title: "businessModel.modal3.title",
          des: 'businessModel.modal3.des'
        },
        {
          icon: require("@/common/img/DeTok/bm4.png"),
          title: "businessModel.modal4.title",
          des: 'businessModel.modal4.des'
        },
        {
          icon: require("@/common/img/DeTok/bm5.png"),
          title: "businessModel.modal5.title",
          des: 'businessModel.modal5.des'
        },
        {
          icon: require("@/common/img/DeTok/bm6.png"),
          title: "businessModel.modal6.title",
          des: 'businessModel.modal6.des'
        },
        {
          icon: require("@/common/img/DeTok/bm7.png"),
          title: "businessModel.modal7.title",
          des: 'businessModel.modal7.des'
        }
      ],
      navShow: true,
      scrollPre: 0,
      userNums: 0
    }
  },
  mounted() {
    this.valueLang = langSet();
    this.isEn = langSet() == 'en';
    this.swiper()
    this.handleAgent();
    this.icpShow = window.location.href.indexOf('info') > -1;
    if (langSet() == 'ch') {
      document.title = '乐唰短视频官网';
      this.handleMetaKeywords();
    }
    if (this.isWeixin) {
      this.initWxConfig();
    }

    // 监听页面滚动
    window.addEventListener("scroll",this.onScroll);

    this.getPrice();
  },
  methods: {

    // 获取人数和价格
    getPrice() {
      let that = this;
      $.ajax({
        type : "post",  //提交方式  
        url: "https://www.atoshi.org/api/price/findPrice",//路径 
        contentType: "application/json",
        dataType: 'json',
        data: JSON.stringify({}),// 数据
        success : function(res) {//返回数据根据结果进行相应的处理
          if (res.code == 100) {
            let resData = res.data,
              userCoun = new BigNumber(resData.userCount).plus(new BigNumber(600000));
            
            that.userNums = parseInt(userCoun).toLocaleString();
          }
        }  
      });
    },

    // 导航栏下滑隐藏，上滑显示
    onScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if ((scrollTop < this.scrollPre) != this.navShow) {
        this.navShow = scrollTop < this.scrollPre;
      }
      this.scrollPre = scrollTop;
    },
    
    showBeta() {
      this.betaFlag = !this.betaFlag;
    },

    initWxConfig() {
      // console.log(window.wx);
      $.ajax({
        type : "post",  //提交方式  
        url: "https://api.leshuapro.com/api/wechatH5Share/getRequestParam",//路径 
        contentType: "application/json",
        dataType: 'json',
        data: JSON.stringify({
          url: window.location.href
        }),//数据，这里使用的是Json格式进行传输  
        success : function(result) {//返回数据根据结果进行相应的处理  
          let res = result.data.entity;
          let wx = window.wx;
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: "wxd548ca5881a8220c", // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.noncestr, // 必填，生成签名的随机串
            signature: res.signature, // 必填，签名
            jsApiList: ['updateAppMessageShareData'] // 必填，需要使用的JS接口列表
          });
          // // 需在用户点击分享按钮前就先调用
          wx.ready(function () {
            wx.updateAppMessageShareData({
              title: '乐唰短视频官网', // 分享标题
              desc: '乐唰是全球首个区块链短视频平台，用视频链接全球用户', // 分享描述
              link: res.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: 'https://img.leshuapro.com/wangzhuang_images/ad343488-f8c5-42e8-a3f2-34ad1f28e5cb.png', // 分享图标
              success: function (res) {
                // 设置成功
                console.log(res);
              }
            });
          });
        }  
      });
    },

    // 判断客户端
    handleAgent() {
      var u = window.navigator.userAgent;
      this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // IOS
      this.isWeixin = !!/MicroMessenger/i.test(u); // weixin浏览器
    },
    controlToast(type) {
      if (!this[type] && type === 'videoShow') {
        this.videoSrc = this.introduceSrc;
      }
      this[type] = !this[type];
    },
    // 点击导航
    toastNav(nav) {
      this.active = nav;
      $('html, body').animate({
        scrollTop: $(nav).offset().top
      }, 800);
      this.controlToast('show');
    },
    download(type) {
      if (this.isWeixin) {
        this.controlBrower()
      } else if (this.isiOS) {
        let msg = 'IOS版本研发中,敬请期待!';
        Toast({
          message: msg,
          duration: 2500,
        });
        // window.location.href = APPSTORE_URL;
      } else if (this.isAndroid) {
        // window.location.href = this.isEn ? APK_URL : APK_URL_LS;
        let url = this.isEn ? APK_URL : APK_URL;
        if (type == 'beta') {
          url = APK_URL_BETA;
        }
        window.location.href = url;
        // window.location.href = APK_URL;
      }
    },
    controlBrower() {
      this.browerFlag = !this.browerFlag;
    },
    onClose() {
      // $('.videoToast').trigger('pause');
    },
    
    openLink(url) {
      window.location.href = url;
    },
    wechatClick(type) {
      if (this.showWechat === type) {
        this.showWechat = "";
      } else {
        this.showWechat = type;
      }
      
    },
    onChange(value) {
      this.$i18n.locale = value;
      this.isEn = this.$i18n.locale === 'en';
      document.title = this.$i18n.locale === 'en' ? 'DeTok official website' : '乐唰短视频官网';
    },

    controlView(url) {
      this.picSrc = url;
      this.picShow = !this.picShow;
    },

    swiper() {
      // 计算swiper偏移量
      new Swiper(".swiper-container", {
        watchSlidesProgress: true,
        observer: true,
        observeParents: true,
        // slidesPerView: "auto",
        // centeredSlides: true,
        loop: true,
        loopedSlides: 3,
        // autoplay: {
        // 	delay: 5000,
        // },
        slideToClickedSlide: true,
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
        effect: "coverflow",
        slidesPerView: 3,
        centeredSlides: true,
        coverflowEffect: {
          rotate: 30,
          stretch: 10,
          depth: 60,
          modifier: 2,
          slideShadows: true
        }
      });
    },

    // 更换关键词内容
    handleMetaKeywords() {
      var metaList = document.getElementsByTagName("meta");
      for (var i = 0; i < metaList.length; i++) {
        if (metaList[i].getAttribute("name") == "keywords") {
          metaList[i].content = "乐唰是全球首个区块链短视频平台，用视频链接全球用户";
        }
      }
    },
  }
}
</script>

<style scoped>


.flex {
  display: flex;
}
.flexable {
  display: flex;align-items: center;
}
.jusCenter {
  justify-content: center;
}
.between {
  justify-content: space-between;
}
.wrap {
  flex-wrap: wrap;
}
.column {
  flex-direction: column;
}


.color_w {
  color: #FFFFFF
}

.color_3 {
  color: #333;
}

.color_title {
  color: #FD5AA2;
}
/* 正文标题 */
.color_fontTitle {
  color: #1B1B1B;
}
/* 正文标题1 */
.color_fontTitle1 {
  color: #333333;
}
/* 正文描述 */
.color_fontDes {
  color: #666666;
}


.font_14 {
  font-size: 0.14rem;
}
.font_16 {
  font-size: 0.16rem;
}
.font_18 {
  font-size: 0.18rem;
}
.font_20 {
  font-size: 2.6vw;
}
.font_24 {
  font-size: 3.2vw;
}
.font_26 {
  font-size: 3.5vw;
}
.font_28 {
  font-size: 3.7vw;
}
.font_30 {
  font-size: 0.3rem;
}
.font_32 {
  font-size: 4.2vw;
}
.font_34 {
  font-size: 0.34rem;
}
.font_36 {
  font-size: 4.8vw;
}
.font_40 {
  font-size: 0.4rem;
}
.font_42 {
  font-size: 0.42rem;
}


.font_bold {
  font-weight: bold;
}
.font_bolder {
  font-weight: bolder;
}

.text_center {
  text-align: center;
}
.text_left {
  text-align: left;
}
.text_right {
  text-align: right;
}

.marLeft20 {
  margin-left: 2.6vw;
}

.marLeft37 {
  margin-left: 0.37rem;
}

.marRight40 {
  margin-right: 0.4rem;
}

.marTop8 {
  margin-top: 1vw;
}

.marTop20 {
  margin-top: 2.6vw;
}

.marTop27 {
  margin-top: 0.27rem;
}

.marTop30 {
  margin-top: 4vw;
}

.marTop40 {
  margin-top: 5.3vw;
}

.lh40 {
  line-height: 5.3vw;
}
.lh48 {
  line-height: 6.4vw;
}

.title_public {
  color: #FD5AA2;font-size: 5.3vw;margin: 17vw 0 5.3vw 0;text-align: center;font-weight: 600;
}

/* 导航栏 */

.navigation {
  width: 100%;
  height: 15vw;
  background: rgba(0, 12, 64, .8);
  padding: 0 4vw;
  box-sizing: border-box;
  position: fixed;top: 0;left: 0;z-index: 99;
}

.logo {
  width: 18.6vw;
}

.btn_download {
  width: 20.2vw;height: 7.5vw;text-align: center;background: #00E2D5;
  border-radius: 5.3vw;font-size: 3.5vw;
}

.navMore {
  width: 6.4vw;margin-left: 3.2vw;
}

/* 首页 */

#homePage {
  width: 100%;height: 150vw;position: relative;overflow: hidden;
}

.homePage_bg {
  width: 100vw;position: absolute;top: 0;left: 0;
}
.homePage_girl {
  width: 72vw;position: absolute;bottom: -3vw;right: -7vw;
}
.homePage_box {
  position: absolute;left: 5.3vw;top: 22vw;
}

.homePage_imgFont {
  width: 25vw;
}

.homePage_imgFont1 {
  width: 79vw;margin-top: 5.3vw;
}

.homePage_des {
  line-height: 6.4vw;letter-spacing: 100;margin-top: 6.4vw;
}

.homePage_btnBox {
  margin: 23vw 0 0 7.5vw;
}

.homePage_btn {
  width: 35.2vw;height: 9.6vw;font-size: 0.24rem;border-radius: 0.5rem;
  background: linear-gradient(to right, #fd8cef 0%, #bc99f1 100%);font-weight: bold;
}

.homePage_btn1 {
  width: 2.5rem;line-height: 0.66rem;text-align: center;font-size: 0.24rem;border-radius: 0.5rem;position: relative;
  background: linear-gradient(to right, #fd8cef 0%, #bc99f1 100%);margin-top: 0.3rem;font-weight: bold;
}

.homePage_btnImg {
  width: 5.3vw;margin-right: 1vw;
}

/* 项目介绍 */

#content1 {
  width: 100%;padding: 0.55rem 0.3rem 0.9rem 0.3rem;box-sizing: border-box;background: #fff;
  position: relative;
}



/* 商业模式 */

#businessModel {
  width: 100%;padding: 0 4vw;box-sizing: border-box;
}
.businessModelItem {
  width: 100%;background: #F7F6FC;border-radius: .5vw;padding: 6.4vw;box-sizing: border-box;margin-top: 2.7vw;
}


/* 规划特点 */

#plan {
  width: 100%;position: relative;
}
.planItem_top {
  width: 50%;height: 75vw;padding: 5.3vw 4.5vw 0 4.5vw;box-sizing: border-box;
}
.planItem_btm {
  width: 50%;height: 85vw;padding: 23vw 4.5vw 0 4.5vw;box-sizing: border-box;
}
.color_plan1 {
  background: #EAFEDA;
}
.color_plan2 {
  background: #ADF5FF;
}
.color_plan3 {
  background: #DBC9FB;
}
.color_plan4 {
  background: #FFDAB8;
}
.planImg {
  width: 84vw;position: absolute;left: 50%;top: 55vw;transform: translateX(-50%);
}
.planImg_shape {
  width: 25vw;position: absolute;top: -8vw;right: 0;
}
.planImg_shape1 {
  width: 29vw;position: absolute;bottom: -40vw;left: 0;
}



/* 将重塑场景 */

#scene {
  width: 100%;background: #fff;padding: 0 4vw 17vw 4vw;box-sizing: border-box;text-align: center;
}

.scene_item {
  width: 44.8vw;min-height: 47vw;margin-top: 2.6vw;background: #F7F6FC;border-radius: .5vw;
  padding: 6.4vw 5.3vw 0 5.3vw;box-sizing: border-box;
}

.scene_itemOne {
  width: 100%;min-height: 47vw;background: #F7F6FC;border-radius: .5vw;
  padding: 6.4vw 5.3vw 0 5.3vw;box-sizing: border-box;
}

.scene_itemImg {
  width: 10.6vw;
}



/* DeTok 回购应用场景 */

#repurchase {
  width: 100%;padding: .1vw 6.4vw 14vw 6.4vw;box-sizing: border-box;background: #F7F6FC;
}



/* 联系我们 */

#footer {
  width: 100%;background: linear-gradient(to top right, #FABF70 0%, #F669F5 60%, #1ADEF2 100%);
  padding-bottom: 5.8vw;box-sizing: border-box;
}

.footer_icon {
  width: 100%;padding: 8.5vw 8vw 10.6vw 5.3vw;box-sizing: border-box;
}

.footer_icon li {
  margin-top: 5.3vw;
}

.footer_else {
  border-top: 1px solid rgba(255,255,255,.5);
}

.bgAtoshi {
  margin-top: 2.4vw;
}

.icp {
  display: inline-block;width: 100%;
}



.imgApp {
  width: 1.44rem;height: 1.44rem;
}

.appDesBox {
  align-items: flex-start;justify-content: center;flex-direction: column;margin-left: 0.17rem;
}

.downIconBox {
  justify-content: center;width: 28vw;height: 7.5vw;background: rgba(0,0,0,.3);border-radius: 0.5rem;
}

.downIconBoxEn {
  justify-content: center;width: 28vw;height: 7.8vw;background: rgba(0,0,0,.3);border-radius: 0.5rem;
}

.downIcon {
  width: 3.4vw;height: 3.8vw;
}

.iosExploit {
  width: 1.5rem;text-align: center;position: absolute;bottom: -0.5rem;left: 0;
}

.wechatIcon {
  width: 1rem;height: 1rem;position: absolute;left: -1.1rem;top: -0.18rem;
}

.insIcon {
  width: 1rem;height: 1rem;position: absolute;right: -1.1rem;top: -0.18rem;
}

.social_icon {
  width: 0.64rem;height: 0.64rem;
}

/* 弹出导航 */

.popupShadaw {
  width: 70%;height: 100%;background: rgba(0, 12, 64, .9);
}

.toastBox {
  margin-top: 12vw;padding-left: 6.1vw;box-sizing: border-box;
}

.toastBox img {
  width: 5.3vw;
}

.toastBox li {
  line-height: 13.3vw;
  /* text-align: center; */
  text-decoration: none;
  color: rgba(254, 254, 254, .7);
  /* font-weight: bold; */
  font-size: 4vw;
  display: flex;
  align-items: center;
}

.toastBox span {
  margin-left: 2.6vw;
}

.active {
  color: #fff !important;
}

>>> .van-dropdown-menu {
  width: 1.3rem;height: .5rem;line-height: .5rem;
}

>>> .van-dropdown-menu__bar {
  height: auto;
}

>>> .vant-overlay {
  background-color: rgba(0,0,0,.7);
}

>>> .van-dropdown-menu__item {
  justify-content: left!important;
}

>>> .van-dropdown-menu__title:after {
    right: -.4rem;
}

>>> .van-dropdown-item--down {
  top: 1.4rem !important;
}

.img_brower {
  width: 32vw;position: absolute;right: 5.3vw;
}

.textIndent {
  text-indent: 2em;
}



</style>
